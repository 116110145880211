import { defineStore } from 'pinia'

export const useNotify = defineStore('notify', () => {
  const open = ref(false)
  const color = ref<string>()
  const text = ref<string>()

  const notify = (m: string, c: string = 'default') => {
    color.value = c
    text.value = m
    open.value = true
  }

  const success = (message: string) => notify(message, 'success')
  const error = (message: string) => notify(message, 'error')
  const info = (message: string) => notify(message, 'info')


  return {
    open,
    color,
    text,
    success,
    error,
    info,
    notify
  }
})
